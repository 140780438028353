/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    bg: `#181818`,
    pt: [6, 7],
    pb: [5, 6],
    px: [4, 0]
  },
  contentTwoContainer: {
    bg: `#181818`,
    borderRadius: `xl`,
    py: [4, 5],
    px: [4, 0]
  },
  blackCard: {
    bg:`#181818`,
  }
}
